
#company-slider .swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
}
#company-slider .swiper-slide img {
  width: 100%;
  object-fit: contain;
  filter: grayscale(100%);
  transition: all .3s;
  opacity: .8;
}
#company-slider .swiper-slide:hover img {
  filter: grayscale(0);
  opacity: 1;
}

@media (max-width: 767px) {
  #company-slider .swiper-slide {
    height: 100px;
  }
}
