/*------- loading ---------*/
@-webkit-keyframes loading-dots-fadein {
  to {
    opacity: 1;
  }
}
@keyframes loading-dots-fadein {
  to {
    opacity: 1;
  }
}
@-webkit-keyframes loading-dots-first-dot {
  to {
    -webkit-transform: translateX(14px);
    transform: translateX(14px);
    opacity: 1;
  }
}
@keyframes loading-dots-first-dot {
  to {
    -webkit-transform: translateX(14px);
    transform: translateX(14px);
    opacity: 1;
  }
}
@-webkit-keyframes loading-dots-middle-dots {
  to {
    -webkit-transform: translateX(14px);
    transform: translateX(14px);
  }
}
@keyframes loading-dots-middle-dots {
  to {
    -webkit-transform: translateX(14px);
    transform: translateX(14px);
  }
}
@-webkit-keyframes loading-dots-last-dot {
  to {
    -webkit-transform: translateX(28px);
    transform: translateX(28px);
    opacity: 0;
  }
}
@keyframes loading-dots-last-dot {
  to {
    -webkit-transform: translateX(28px);
    transform: translateX(28px);
    opacity: 0;
  }
}

div.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 1000;
  top: 0;
  left: 0;
  background: #fff;
}
div.loading .wrapper {
  width: 250px;
  height: 150px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
  background: #fff;
}
.spinner-border {
  color: var(--main-color);
}
div.loading .bullets {
  margin-left: -21px;
  opacity: 0;
  animation: loading-dots-fadein 0.5s linear forwards;
  direction: ltr;
  width: 100%;
  text-align: center;
  clear: both;
}
div.loading .bullet:first-child {
  animation: loading-dots-first-dot 0.5s ease-in-out infinite;
  opacity: 0;
  transform: translateX(-14px);
}
div.loading .bullet {
  border-radius: 100%;
  overflow: hidden;
  background-color: var(--main-color);
  width: 7px;
  height: 7px;
  display: inline-block;
  vertical-align: middle;
  margin: 0 4px;
  animation: loading-dots-middle-dots 0.5s ease-in-out infinite;
}
div.loading .bullet:last-child {
  animation: loading-dots-last-dot 0.5s ease-in-out infinite;
}

div.loading .wrapper img {
  width: 80px;
}
