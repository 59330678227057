#main-footer{
  background-color: #333;
  color: #fff;
}
#main-footer a{
  color: var(--secondary-text-color);
}
#main-footer a.letter-spacing{
  letter-spacing: 2px;
}

#main-footer i{
  margin-right: .25rem;
  color: #e9c54d;
}

#main-footer h3 {
  position: relative;
  padding-bottom: 1rem;
  margin-bottom: 2rem;
}
#main-footer h3:after {
  content: '';
  position: absolute;
  height: 2px;
  width: 100px;
  background: #e9c54d;
  opacity: 0.3;
  display: block;
  bottom: 0;
}