#main-header{
  background-color: var(--main-color);
  box-shadow: 0 4px 8px rgba(0,0,0,.28);
  color: var(--secondary-text-color);
  position: sticky;
  top: 0;
  z-index: 1000;
}

#main-header img.logo{
  filter: brightness(100);
}

#sidebar-backdrop{
  position: fixed;
  width: 100vw;
  left: 0;
  transition: all .3s;
  top: 0;
  height: 100vh;
  z-index: -1;
  opacity: 0;
}
#sidebar-backdrop.show{
  opacity: 1;
  z-index: 900;
  background-color: rgba(0,0,0,.7);
}

#main-sidebar{
  position: fixed;
  width: 300px;
  color: #fff;
  left: -300px;
  transition: all .3s;
  top: 0;
  height: 100vh;
  z-index: 1000;
}
#main-sidebar .ui.menu{
  height: 100%;
}
#main-sidebar.show{
  left: 0;
}

.toggle-sidebar{
  color: #fff;
}