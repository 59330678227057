:root {
  --main-bg-color: #fefefe;
  --main-text-color: #172b5b;
  --main-color: #266ab3;
  --secondary-text-color: #fff;
}

html {
  font-size: 14px;
  scroll-behavior: smooth;
}

body {
  background: var(--main-bg-color);
}

img {
  max-width: 100%;
}

.ui.container {
  margin-left: auto!important;
  margin-right: auto!important;
}
.ui.fluid.container {
  width: 100%;
}


@media (max-width: 767px) {
  /* On small screens, the nav menu spans the full width of the screen. Leave a space for it. */
  body {
    padding-top: 0;
  }

  .w-25,
  .w-50 {
    width: calc(100% - 1rem) !important;
    max-width: 75vw;
  }
  .w-75 {
    width: calc(100% - 1rem) !important;
  }

  .cards {
    width: 100% !important;
  }

  .tag .ui.card,
  .ui.cards > .card {
    width: calc(100% - 1rem) !important;
  }
}

main {
  min-height: calc(100vh - 413px);
}

a {
  color: var(--main-text-color);
}

p {
  text-align: justify;
}

.blur-sm {
  filter: blur(4px);
  user-select: none;
}

.blur-lg {
  filter: blur(8px);
  user-select: none;
}

a:hover {
  text-decoration: none;
}

body .hidden,
body .hidden.visible,
body .hidden.visible.transition {
  display: none !important;
}

.ui.divider:not(.vertical):not(.horizontal) {
  border-color: #d9eaf7;
}

svg.icon {
  max-width: 100%;
}

svg.icon.sm {
  width: 60px;
  height: 60px;
}

.section {
  box-shadow: -3px 3px 6px 0px rgba(0, 0, 0, 0.16);
  border: 1px solid #d5d4d6;
  border-radius: 4px;
  background-color: #fff;
  padding: 1rem;
  position: relative;
}
.ui.breadcrumb .section {
  border: 0;
  box-shadow: none;
}

.ui.selection.dropdown.active,
.ui.selection.dropdown.visible {
  z-index: 2000;
}

.section input {
  position: absolute;
  cursor: pointer;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
}

span.capital-first,
b.capital-first {
  display: inline-block;
}

.capital-first::first-letter {
  text-transform: capitalize;
}

.ui.popup {
  max-width: 400px;
  text-align: justify;
  white-space: pre-wrap;
  z-index: 9999;
}
body.popover-dragging .ui.popup {
  display: none !important;
}
.wide.column > .trigger-el {
  width: 100%;
}
.trigger-el > h2,
.trigger-el > h3,
.trigger-el > h4,
.trigger-el > h5 {
  margin-bottom: 1rem;
}

.ui.basic.button.light,
.ui.basic.button.light.active {
  background-color: #fff !important;
}
.ui.basic.button.h-100 {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

body .ui.active.button,
body .ui.active.button:hover {
  background-color: var(--main-color);
  color: var(--secondary-text-color);
}

.ui.buttons button.button {
  position: relative;
}
.ui.buttons button.button input {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
}
.ui.percent > input {
  width: 75px;
}

body .ui.slider .inner .track-fill {
  background: var(--primary);
}

input.border-0:focus {
  border: 0 !important;
  outline: 0 !important;
}

/* Chrome, Safari, Edge, Opera */
input[type="number"].text-field::-webkit-outer-spin-button,
.text-field > input[type="number"]::-webkit-outer-spin-button,
.text-field > input[type="number"]::-webkit-inner-spin-button,
input[type="number"].text-field::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input.input-section {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

/* Firefox */
input[type="number"].text-field,
.text-field > input[type="number"] {
  -moz-appearance: textfield;
}

.ui.card:hover {
  transform: translateY(-2px);
}

.eagle {
  position: fixed;
  bottom: 0;
  right: -15px;
  z-index: 100;
  height: 240px;
  /* filter: drop-shadow(-2px 1px 0px #888) drop-shadow(1px -1px 0px #888) drop-shadow(-1px -2px 0px #888) */
}

.eagle.full-body {
  height: 250px;
  right: -50px;
}

body > footer.ui.popup {
  top: auto !important;
  bottom: 200px !important;
  right: 10px !important;
  left: auto !important;
  transform: none !important;
  text-align: center;
}
body[data-motion-folder="2"] > footer.ui.popup {
  bottom: 250px !important;
}

.pre-wrap {
  white-space: pre-wrap;
}

.pre-wrap:first-line {
  font-weight: bold;
  font-size: 1.1rem;
}

.react-datepicker-popper {
  z-index: 1000 !important;
}

.datepicker .react-datepicker {
  font-size: 1.1rem !important;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif !important;
}

.react-datepicker__year-select,
.react-datepicker__month-select {
  padding: 0.5rem 1rem;
  margin: 0.5rem;
}
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  margin: 0 0.25rem;
  min-width: 0;
  padding: 0.25rem 0;
}
.react-datepicker__day-names {
  display: none;
}
.datepicker .react-datepicker-wrapper input {
  margin: 0;
  width: 100%;
  max-width: 200px;
  flex: 1 0 auto;
  outline: 0;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  text-align: left;
  line-height: 1.21428571em;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  padding: 0.67857143em 1em;
  background: #fff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  transition: box-shadow 0.1s ease, border-color 0.1s ease;
  box-shadow: none;
}
.datepicker .react-datepicker-wrapper {
  width: 100% !important;
  max-width: 75vw;
}
.datepicker .react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: 2rem !important;
}

.react-datepicker__current-month,
.react-datepicker__navigation {
  display: none;
}

.child-card {
  transform: none !important;
}

#top-footer {
  background-color: #273133;
}

.province-hidden {
  position: absolute;
  opacity: 0;
  width: 200px;
  height: 50px;
  width: 1px;
  height: 1px;
}



.ui.grid .section-wrapper.row {
  justify-content: center;
}
.ui.grid .section-wrapper.path .button > .icon {
  padding: 1rem;
  width: 4em;
}
.ui.grid .section-wrapper.path .button > .content {
  width: calc(100% - 40px);
}
.ui.grid > .section-wrapper.row > .column {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}
.section-wrapper .section.active {
  border-color: var(--main-color);
}
.section-wrapper .section {
  transition: all 0.2s;
}
.section-wrapper .section,
.section-wrapper .column .section:hover {
  display: flex;
  transition: all 0.2s;
  height: 100%;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 8px 20px 0px rgba(0, 0, 0, 0.125);
  position: relative;
  overflow: hidden;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.section-wrapper:hover .section,
.section-wrapper.selected > .section,
.section-wrapper.selected > .column > .section {
  transform: scale(1);
  filter: blur(0.7px);
  opacity: 0.5;
  box-shadow: none;
  max-width: 40vw;
  max-height: 500px;
  transition: all 0.3s;
}
.section-wrapper .section:hover,
.section-wrapper .section.active,
.section-wrapper.selected > .column > .section.active {
  transform: scale(1) perspective(1px) translateZ(0);
  filter: blur(0);
  opacity: 1;
}

.section-wrapper .section:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--main-color);
  height: 4px;
  -webkit-transform: translateY(4px);
  transform: translateY(4px);
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.section-wrapper .section:hover:before,
.section-wrapper .section.active:before {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
.section-wrapper .section label {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
  height: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: bold;
  font-size: 1.1rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.section-wrapper.have-icon .section label {
  flex-direction: column;
  font-size: 1.3rem;
}

.section-wrapper.have-icon svg.icon {
  margin-bottom: 1.5rem;
}
.section-wrapper.have-icon.sm svg.icon {
  width: 90px;
  height: 90px;
}
.title-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.title-icon svg.icon {
  width: 50px;
  height: 50px;
  margin-right: 1rem;
}
.section-wrapper.have-icon.sm .section label {
  font-size: 1.1rem;
}
.section-wrapper.have-icon.sm .icon {
  margin-bottom: 0.5rem;
}

.section-wrapper .section .check-mark {
  display: none;
  position: absolute;
  right: 4px;
  top: 4px;
  width: 50px;
}
.section-wrapper .section.active .check-mark,
.section-wrapper .section input:checked + .check-mark {
  display: block;
  width: 26px;
}

.section-wrapper .ui.labeled.button {
  padding: 1.25em 0.75em 1.25em 3em !important;
  text-align: center;
  line-height: 20px;
  /* white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden; */
  width: 100%;
}

@media (max-width: 768px) {
  

  .section-wrapper .section label {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }

  .section-wrapper.have-icon .section label{
    font-size: 1rem;
  }

  .section-wrapper .ui.basic.labeled.button {
    padding: .5em .25em .5em 3em !important;
    min-height: 70px;
  }
  .section-wrapper .ui.labeled.button .content{
    font-size: 1.2rem;
  }

  .section-wrapper.selected > .column > .section{
    max-width: 75vw;
  }

  .ui.grid > .doubling.stackable.row > .column,
  .ui.doubling.grid > .column.grid > .column,
  .ui.doubling.grid > .column.row > .column,
  .ui.doubling.grid > .column:not(.row),
  .ui.doubling.grid > .row > .column,
  .ui.doubling.grid > .row > .wide.column,
  .ui.doubling.grid > .wide.column {
    width: 50% !important;
  }
  .ui.doubling.grid {
    width: auto;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .ui.grid > .stackable.stackable.row > .column,
  .ui.stackable.grid > .column.grid > .column,
  .ui.stackable.grid > .column.row > .column,
  .ui.stackable.grid > .column:not(.row),
  .ui.stackable.grid > .row > .column,
  .ui.stackable.grid > .row > .wide.column,
  .ui.stackable.grid > .wide.column {
    padding: 0.5rem 0.5rem !important;
  }
  .ui.grid > .stackable.stackable.row > .column:last-child,
  .ui.stackable.grid > .column.grid > .column:last-child,
  .ui.stackable.grid > .column.row > .column:last-child,
  .ui.stackable.grid > .column:not(.row):last-child,
  .ui.stackable.grid > .row > .column:last-child,
  .ui.stackable.grid > .row > .wide.column:last-child,
  .ui.stackable.grid > .wide.column:last-child {
    padding-bottom: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .ui.grid > .column:not(.row),
  .ui.grid > .row > .column {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .ui.dropdown .scrolling.menu,
  .ui.scrolling.dropdown .menu {
    max-height: 18em;
  }

  .ui.divider {
    margin: 2rem 0;
  }

  .cash-investment-fields h4 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 0;
  }
  .cash-investment-fields h4 .input {
    margin-top: 0.25rem;
  }

  .ui.grid > .row.section-wrapper.selected > .column > .section:not(.active) {
    max-width: 0;
    max-height: 0;
    opacity: 0;
    padding-bottom: 0 !important;
    padding-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .ui.stackable.grid > .row.section-wrapper.selected > .column,
  .ui.stackable.grid > .row.section-wrapper.selected > .wide.column,
  .ui.stackable.grid > .row.section-wrapper.selected > .wide.column:last-child,
  .ui.stackable.grid > .row.section-wrapper.selected > .column:last-child,
  .ui.grid.doubling > .row.section-wrapper.selected > .column,
  .ui.grid.doubling > .row.section-wrapper.selected > .wide.column,
  .ui.grid.doubling > .row.section-wrapper.selected > .wide.column:last-child,
  .ui.grid.doubling > .row.section-wrapper.selected > .column:last-child {
    width: 100% !important;
    padding: 0 !important;
    margin: 0 !important;
  }
  .ui.grid > .row.section-wrapper.selected > .column > .section.active {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
    height: auto;
  }
  svg.icon {
    max-height: 70px;
  }

  .ui.grid > .row.section-wrapper {
    max-width: 85vw;
  }
  .ui.grid {
    justify-content: center;
  }

  body > footer.ui.popup {
    bottom: 80px !important;
  }
}
@media (min-width: 992px) {
  .container.sm {
    max-width: 992px !important;
  }
}
@media only screen and (min-width: 1400px) {
  .ui.container {
    width: 1300px !important;
  }
}
